


















import { defineComponent, SetupContext } from '@vue/composition-api'

import { DrawerProps } from './Drawer.contracts'
import { drawerProps, useDrawer } from './Drawer.hooks'

/**
 * Component capable to render `Drawer` element.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const Drawer = defineComponent({
  name: 'Drawer',
  props: drawerProps,

  setup (props: DrawerProps, ctx: SetupContext) {
    return useDrawer(props, ctx.emit)
  }
})
export default Drawer
